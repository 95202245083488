var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","width":"1240px","max-width":"1240px","scrollable":"","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[(_vm.pageLoading)?_c('SkeletonPreloader',{attrs:{"type":"article, actions"}}):[(_vm.details)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.makeOrder)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"font-weight-bold blue-grey--text font-size-lg ft ml-1"},[_vm._v("Edit Order")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","color":_vm.details && _vm.details.isOrderReviewed ? 'success' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.details && _vm.details.isOrderReviewed ? 'success' : 'primary',"icon":"","small":""}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("verified")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(" "+_vm._s(_vm.details && _vm.details.isOrderReviewed ? "Orders is already reviewed" : "Order is pending revision"))])])],1),_c('v-divider'),_c('v-card-text',[_c('v-col',[_c('v-row',{staticClass:"px-2 pt-6 d-flex flex-row flex-grow-1",staticStyle:{"background":"#f7f7f7","border-radius":"8px"}},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","readonly":_vm.details && _vm.details.isOrderReviewed,"label":"Branches","items":_vm.branches,"item-text":"name","item-value":"id","error-messages":errors[0],"hint":"Name of the branch"},model:{value:(_vm.lineItem.branchId),callback:function ($$v) {_vm.$set(_vm.lineItem, "branchId", $$v)},expression:"lineItem.branchId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","readonly":_vm.details && _vm.details.isOrderReviewed,"items":_vm.products,"item-value":"id","item-text":"name","label":"Product","error-messages":errors[0],"hint":"Name of the product"},model:{value:(_vm.lineItem.productId),callback:function ($$v) {_vm.$set(_vm.lineItem, "productId", $$v)},expression:"lineItem.productId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","readonly":_vm.details && _vm.details.isOrderReviewed,"label":"Quantity (Litres)","error-messages":errors[0],"hint":"Quantity Of Product"},model:{value:(_vm.lineItem.quantity),callback:function ($$v) {_vm.$set(_vm.lineItem, "quantity", $$v)},expression:"lineItem.quantity"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","readonly":_vm.details && _vm.details.isOrderReviewed,"label":"Cheque Number","error-messages":errors[0],"hint":"Cheque number"},model:{value:(_vm.lineItem.chequeNumber),callback:function ($$v) {_vm.$set(_vm.lineItem, "chequeNumber", $$v)},expression:"lineItem.chequeNumber"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","readonly":_vm.details && _vm.details.isOrderReviewed,"label":"Cheque Amount","error-messages":errors[0],"hint":"Amount on cheque"},model:{value:(_vm.lineItem.chequeAmount),callback:function ($$v) {_vm.$set(_vm.lineItem, "chequeAmount", $$v)},expression:"lineItem.chequeAmount"}})]}}],null,true)})],1),(_vm.details.isOrderReviewed)?[_c('div',{staticClass:" pt-4 pb-2 ft font-weight-medium font-size-sm black--text text-uppercase"},[_vm._v(" Order Details ")]),_c('v-row',{staticClass:"px-2  d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","label":"Order Number","error-messages":errors[0],"hint":"Enter the order number here"},model:{value:(_vm.order.orderNumber),callback:function ($$v) {_vm.$set(_vm.order, "orderNumber", $$v)},expression:"order.orderNumber"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"}},[_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","label":"Invoice Number","hint":"Enter the order number here"},model:{value:(_vm.order.invoiceNumber),callback:function ($$v) {_vm.$set(_vm.order, "invoiceNumber", $$v)},expression:"order.invoiceNumber"}})],1),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","autofocus":"","prefix":_vm.currency.length > 0 ? _vm.currency[0].code : 'GHS',"label":"Net Amount","error-messages":errors[0],"hint":"Enter the net amount here"},model:{value:(_vm.order.netAmount),callback:function ($$v) {_vm.$set(_vm.order, "netAmount", $$v)},expression:"order.netAmount"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","label":"Delivery Number","error-messages":errors[0],"hint":"Enter order delivery number here"},model:{value:(_vm.order.deliveryNumber),callback:function ($$v) {_vm.$set(_vm.order, "deliveryNumber", $$v)},expression:"order.deliveryNumber"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{staticClass:"ft font-weight-medium mt-n1 ",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-left":0,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ft font-weight-medium pa-1",attrs:{"outlined":"","readonly":"","label":"Loading Date","error-messages":errors[0],"hint":"Choose a date"},model:{value:(_vm.order.loadingDate),callback:function ($$v) {_vm.$set(_vm.order, "loadingDate", $$v)},expression:"order.loadingDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"font font-weight-medium  ",attrs:{"event-color":"primary"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.order.loadingDate),callback:function ($$v) {_vm.$set(_vm.order, "loadingDate", $$v)},expression:"order.loadingDate"}})],1)]}}],null,true)})],1)]:_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ft text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft white--text text-capitalize",attrs:{"color":"primary","disabled":_vm.loading || _vm.pageLoading,"loading":_vm.loading,"type":"submit"}},[_vm._v(" Save ")])],1)],1)],1)]}}],null,false,779093809)}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }