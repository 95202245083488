<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    width="1240px"
    max-width="1240px"
    scrollable
    persistent
  >
    <SkeletonPreloader v-if="pageLoading" type="article, actions" />
    <template v-else>
      <ValidationObserver v-if="details" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(makeOrder)" class="px-3">
          <v-card flat ref="form">
            <v-card-title>
              <span
                class="font-weight-bold blue-grey--text font-size-lg ft ml-1"
                >Edit Order</span
              >
              <v-spacer></v-spacer>
              <v-tooltip
                left
                :color="
                  details && details.isOrderReviewed ? 'success' : 'primary'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :color="
                      details && details.isOrderReviewed ? 'success' : 'primary'
                    "
                    icon
                    small
                  >
                    <i class="material-icons-outlined">verified</i>
                  </v-btn>
                </template>
                <span class="ft font-weight-medium font-size-sm">
                  {{
                    details && details.isOrderReviewed
                      ? "Orders is already reviewed"
                      : "Order is pending revision"
                  }}</span
                >
              </v-tooltip>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-col>
                <v-row
                  class="px-2 pt-6 d-flex flex-row flex-grow-1"
                  style="background: #f7f7f7;border-radius: 8px;"
                >
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-autocomplete
                      outlined
                      autofocus
                      :readonly="details && details.isOrderReviewed"
                      label="Branches"
                      class="ft font-weight-medium pa-1"
                      :items="branches"
                      v-model="lineItem.branchId"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors[0]"
                      hint="Name of the branch"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-autocomplete
                      outlined
                      :readonly="details && details.isOrderReviewed"
                      v-model="lineItem.productId"
                      class="ft font-weight-medium pa-1"
                      :items="products"
                      item-value="id"
                      item-text="name"
                      label="Product"
                      :error-messages="errors[0]"
                      hint="Name of the product"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required|numeric"
                  >
                    <v-text-field
                      outlined
                      :readonly="details && details.isOrderReviewed"
                      v-model="lineItem.quantity"
                      label="Quantity (Litres)"
                      class="ft font-weight-medium pa-1"
                      :error-messages="errors[0]"
                      hint="Quantity Of Product"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      outlined
                      :readonly="details && details.isOrderReviewed"
                      v-model="lineItem.chequeNumber"
                      class="ft font-weight-medium pa-1"
                      label="Cheque Number"
                      :error-messages="errors[0]"
                      hint="Cheque number"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required|decimals"
                  >
                    <v-text-field
                      outlined
                      :readonly="details && details.isOrderReviewed"
                      v-model="lineItem.chequeAmount"
                      class="ft font-weight-medium pa-1"
                      label="Cheque Amount"
                      :error-messages="errors[0]"
                      hint="Amount on cheque"
                    />
                  </ValidationProvider>
                </v-row>
                <template v-if="details.isOrderReviewed">
                  <div
                    class=" pt-4 pb-2 ft font-weight-medium font-size-sm black--text text-uppercase"
                  >
                    Order Details
                  </div>
                  <v-row class="px-2  d-flex flex-row flex-grow-1">
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="order.orderNumber"
                        outlined
                        label="Order Number"
                        :error-messages="errors[0]"
                        class="ft font-weight-medium pa-1"
                        hint="Enter the order number here"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                    >
                      <v-text-field
                        outlined
                        v-model="order.invoiceNumber"
                        label="Invoice Number"
                        hint="Enter the order number here"
                        class="ft font-weight-medium pa-1"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      v-slot="{ errors }"
                      rules="required|decimals"
                    >
                      <v-text-field
                        outlined
                        autofocus
                        :prefix="currency.length > 0 ? currency[0].code : 'GHS'"
                        label="Net Amount"
                        v-model="order.netAmount"
                        class="ft font-weight-medium pa-1"
                        :error-messages="errors[0]"
                        hint="Enter the net amount here"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        v-model="order.deliveryNumber"
                        class="ft font-weight-medium pa-1"
                        label="Delivery Number"
                        :error-messages="errors[0]"
                        hint="Enter order delivery number here"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-left="0"
                        offset-y
                        min-width="auto"
                        class="ft font-weight-medium mt-n1 "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-on="on"
                            v-bind="attrs"
                            readonly
                            v-model="order.loadingDate"
                            class="ft font-weight-medium pa-1"
                            label="Loading Date"
                            :error-messages="errors[0]"
                            hint="Choose a date"
                          />
                        </template>
                        <v-date-picker
                          class="font font-weight-medium  "
                          v-model="order.loadingDate"
                          event-color="primary"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </ValidationProvider>
                  </v-row>
                </template>
              </v-col>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn class="mr-2 ft text-capitalize" text @click="close">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                :disabled="loading || pageLoading"
                :loading="loading"
                type="submit"
                class="ft white--text text-capitalize"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </ValidationObserver>
    </template>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { mapGetters } from "vuex";
import { required, numeric } from "vee-validate/dist/rules";
import { decimals } from "../../../../utils/resolvers";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
extend("decimals", decimals);
export default {
  name: "NewOrderDialog",
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageLoading: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object,
      default: null
    },
    currency: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: () =>
      import(`../../../../../components/SkeletonPreloader`)
  },
  data: () => ({
    menu: false,
    order: {
      loadingDate: null,
      orderNumber: "",
      invoiceNumber: "",
      orderLineItemId: "",
      orderId: "",
      deliveryNumber: "",
      netAmount: 0
    },
    lineItem: {
      quantity: 0,
      productId: null,
      branchId: null,
      chequeNumber: null,
      chequeAmount: 0
    }
  }),
  computed: {
    ...mapGetters({
      branches: "orders/getAllBranches",
      products: "orders/getAllProducts"
    })
  },
  watch: {
    details(payload) {
      const {
        branchId,
        productId,
        loadingDate,
        quantity,
        chequeId,
        deliveryNumber,
        netAmount,
        invoiceNumber,
        orderNumber,
        id,
        orderId
      } = payload;
      this.lineItem = {
        id,
        branchId: branchId?.id,
        productId: productId?.id,
        chequeNumber: chequeId?.number,
        chequeAmount: chequeId?.amount,
        quantity
      };
      this.order = {
        loadingDate,
        invoiceNumber,
        deliveryNumber,
        netAmount,
        orderNumber: orderNumber,
        orderLineItemId: id,
        orderId: orderId?.id,
        chequeId: chequeId?.id
      };
    }
  },
  methods: {
    makeOrder() {
      this.$emit("order", {
        loading: { idx: "edit", status: false },
        body: {
          ...this.order,
          orderLineItem: [this.lineItem]
        }
      });
    },
    close() {
      this.$emit("actions", "edit");
    }
  }
};
</script>
